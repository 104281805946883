var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "bg--white border-radius m-t-25 m-b-25 p-30" }, [
      _c("h1", [_vm._v("\n            Dashboard\n        ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ak-button-group" },
        [
          _vm._l(_vm.menu, function (item) {
            return [
              !item.items.length && item.href !== _vm.currentUrl
                ? _c("ak-button", { attrs: { href: item.href } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.title) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(item.items, function (childItem) {
                return [
                  _c("ak-button", { attrs: { href: childItem.href } }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(childItem.title) +
                        "\n                    "
                    ),
                  ]),
                ]
              }),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }