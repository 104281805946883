<template>
    <div class="container">
        <div class="bg--white border-radius m-t-25 m-b-25 p-30">
            <h1>
                Dashboard
            </h1>
            <div class="ak-button-group">
                <template v-for="item in menu">

                    <ak-button
                        v-if="!item.items.length && item.href !== currentUrl"
                        :href="item.href"
                    >
                        {{ item.title }}
                    </ak-button>

                    <template v-for="childItem in item.items">
                        <ak-button
                                :href="childItem.href"
                        >
                            {{ childItem.title }}
                        </ak-button>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Dashboard',
        data() {
            return {
                menu: [],
                currentUrl: window.location.href
            }
        },
        created() {
            const userInterface = this.$userInterfaceHandler.getUserInterface();

            if (userInterface) {
                this.menu = userInterface.menu;
            }
        }
    }
</script>
