<template>
    <ak-wrapper :class="{'ak-wrapper--no-header': $authService && !$authService.isAuthenticated()}">
        <ak-header
            v-if="$authService.isAuthenticated() && userInterface"
            v-model="showDrawer"
            :drawerPinned="drawerPinned"
        >
            <template v-slot:header-left>
<!--                <cms-active-workspace/>-->
                <ak-popover-menu
                        v-if="activeWorkspace"
                        direction="left"
                        :showOverflow="true"
                >
                    <template v-slot:trigger="{open:open, toggleMenu:toggleMenu}">
                        <ak-button
                                class="ak-popover-trigger"
                                colorMode="text"
                                :active="open"
                                :disableBackground="true"
                                @click="toggleMenu"
                        >
                            <div><strong>{{ activeWorkspace.title }}</strong></div>
                            <i class="ak-icon m-l-5">expand_more</i>
                        </ak-button>
                    </template>
                    <template v-slot:default="{toggleMenu:toggleMenu}">
                        <ak-popover-menu-item
                                v-for="(menuItem,key) in menuWorkspace"
                                :key="'workspace-item' + key"
                                :href="menuItem.href"
                                :icon="menuItem.icon"
                                :showArrow="false"
                                @click="toggleMenu();"
                        >
                            {{ menuItem.title }}
                        </ak-popover-menu-item>
                    </template>
                </ak-popover-menu>
            </template>

            <template v-slot:header-right>
                <cms-settings-menu />
                <cms-account-menu />
            </template>
        </ak-header>

        <ak-drawer
            :logoPath="$getSetting('assets.logoDrawer')"
            :showDrawer="showDrawer"
            @close="showDrawer = false"
            @togglePinned="drawerPinned = $event"
        >
            <ak-menu v-if="userInterface"
                 :items="userInterface.menu"
                 :searchEnabled="true"
                 @menuItemClicked=" !drawerPinned ? showDrawer = false : null"
            >
            </ak-menu>
        </ak-drawer>

        <div class="ak-content"
            :class="{'ak-content--pinned': drawerPinned && $authService && $authService.isAuthenticated()}"
            v-if="!$authService.isAuthenticated() || $authService.isAuthenticated() &&  userInterface"
        >
            <router-view></router-view>
        </div>

        <ak-loader size="large" :loading="loading"/>

    </ak-wrapper>
</template>

<script>
    export default {
        name: 'app',
        data() {
            return {
                loadingState: false,
                showDrawer: false,
                drawerPinned: false,
                userInterface: null,
                loading: false
            }
        },
        computed: {
            /**
             * Active workspace
             * @returns {null|*}
             */
            activeWorkspace() {
                if (! this.userInterface) {
                    return null;
                }
                return this.userInterface.workspace;
            },
            /**
             * Menu workspace
             * @returns {any}
             */
            menuWorkspace() {
                if (! this.userInterface) {
                    return [];
                }

                return this.userInterface.menuWorkspace;
            },
        },
        async created() {
            this.loading = true;

            // if we are logged in we need to get the userInterface
            if(await this.$authService.getIsAuthenticated()) {
                this.userInterface = await this.$userInterfaceHandler.updateUserInterface();
            }

            this.loading = false;
        }
    }
</script>

