import VueRouter from 'vue-router';
import Dashboard from './views/pages/Dashboard.vue';

Vue.use(VueRouter);

let routes = [
    {
        path: '/',
        component: Dashboard,
        meta: {
            title: "dashboard",
        },
        alias: '/home'
    }
];


routes = Vue.cmsRouter.setRoutes(routes);

const router = new VueRouter({
    routes
})

Vue.cmsRouter.setActiveRouter(router);

export default router;
